<template>
  <el-card class="box-card accountNum">
    <template #header>
      <div class="card-header">
        <span class="SubHead">{{ accountStateItem.title }}</span>
        <span>
          <el-link class="mediumFont LinkText" style="float: right;" :underline="false" @click="importLicense"
            >新增广告账号
          </el-link>
        </span>
      </div>
    </template>

    <div class="text item">
      <ul>
        <el-row :gutter="20">
          <el-col :span="6" v-for="item in accountStateItem.accountState" :key="item.id">
            <li class="accountList">
              <p class="Text">
                {{ item.title }}
                <span>
                  <el-tooltip class="item" effect="light" content="" placement="top">
                    <template #content>
                      {{ item.tips }}
                    </template>
                    <i class="el-icon-warning-outline" v-show="item.isshow"></i>
                  </el-tooltip>
                </span>
              </p>
              <p class="MainHead">{{ $filters.thousands(item.total) }}</p>
            </li>
          </el-col>
        </el-row>
      </ul>
    </div>
  </el-card>

  <!-- FB营业执照校验对话框 -->
  <OpenAccount v-if="'fb' === dialogType" @close="dialogType = ''"/>
</template>

<script>
import OpenAccount from './accountList/OpenAccount.vue'
export default {
  name: 'AccountState',
  props: ['accountStateItem'],
  components: { OpenAccount },
  data() {
    //浏览器高度
    var height = window.innerHeight
    //浏览器宽度
    var width = window.innerWidth
    //浏览器
    // 浏览器检查结果
    let browserInfo = getBrowserInfo()
    var browser = browserInfo.browserName + '的版本是：' + browserInfo.versionInfo
    function getBrowserInfo() {
      let sysObj = {}
      let browserInfo = navigator.userAgent.toLowerCase()
      let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
      let vsMsg = browserInfo.match(regular)
      sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
      sysObj.versionInfo = vsMsg[2]
      return sysObj
    }
    // 获取操作系统信息
    function getOsInfo() {
      var userAgent = navigator.userAgent.toLowerCase()
      var name = 'Unknown'
      var version = 'Unknown'
      if (userAgent.indexOf('win') > -1) {
        name = 'Windows'
        if (userAgent.indexOf('windows nt 5.0') > -1) {
          version = 'Windows 2000'
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
          version = 'Windows XP'
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
          version = 'Windows Vista'
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
          version = 'Windows 7'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
          version = 'Windows 8'
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
          version = 'Windows 8.1'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
          version = 'Windows 10'
        } else {
          version = 'Unknown'
        }
      } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone'
      } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac'
      } else if (
        userAgent.indexOf('x11') > -1 ||
        userAgent.indexOf('unix') > -1 ||
        userAgent.indexOf('sunname') > -1 ||
        userAgent.indexOf('bsd') > -1
      ) {
        name = 'Unix'
      } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
          name = 'Android'
        } else {
          name = 'Linux'
        }
      } else {
        name = 'Unknown'
      }
      return { name, version }
    }
    var os = getOsInfo().name
    if (os == 'Windows') {
      os = getOsInfo().version
    }

    return {
      userInfo: '',
      // 客户端信息
      width: width,
      height: height,
      browser: browser,
      os: os,
      dialogType: ''
    }
  },
  created() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
  },
  methods: {
    importLicense() {
      if (this.accountStateItem.usage_status != 1) {
        return this.$message.warning(`${this.accountStateItem.usage_msg}`)
      }
      this.dialogType = 'fb'
      // this.$bus.emit('openCreateFBDialog')
    },
    licenseFormClose() {
      this.licenseDialogVisible = false
    },
    async licenseFormBtn() {
      this.$refs.licenseFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('cloudfb/account_check', {
          entity_name: this.licenseForm.licenseNum,
          height: this.height,
          width: this.width,
          browser: this.browser,
          os: this.os
        })
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          this.$router.push('/login')
        }
        window.open(
          `https://www.facebook.com/chinabusinesses/onboarding/220398642106724/?token=${this.accountStateItem.token}`
        )
        this.licenseDialogVisible = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-card {
  height: 180px;
}
.accountList {
  p {
    margin: 10px 0;
  }
}
.text {
  height: 80px;
}

i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
</style>
